import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
// import Portfolio from '../components/Portfolio'
import Intro from '../components/Intro'
// import Quote from '../components/Quote'
// import Logos from '../components/Logos'
import CTA from '../components/CTA'
import About from '../components/About'
import Services from '../components/Services'
import Team from '../components/Team'
import Highlight from '../components/Highlight'
import Projects from '../components/Projects'
import { LogoWall } from '../components/LogoWall'
import { Proflow } from '../components/Proflow'
import { Rezendo } from '../components/Rezendo'
import { NameSecure } from '../components/NameSecure'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Erfolgreiche Websites"
      keywords={[
        `Apps`,
        `Websites`,
        `Webseiten`,
        `Entwicklung`,
        `Programmierung`,
        `Homepage`,
        `Digital`,
      ]}
    />
    <Intro
      hero
      meta="Dein Studio für"
      main={
        <>
          Erfolgreiche <Highlight>Websites</Highlight> und andere{' '}
          <Highlight>digitale</Highlight> <Highlight>Sachen.</Highlight>
        </>
      }
    />
    <Projects />
    <LogoWall />
    <Services />
    {/*
    <Portfolio />
    <Logos />
    <Quote />
    */}
    <Proflow />
    <NameSecure />
    <Rezendo />
    <About />
    <Team />
    <CTA />
  </Layout>
)

export default IndexPage
