import React from 'react'
import styled from 'styled-components'

import Content from './Content'
import Title from './Title'
import Headline from './Headline'

const Wrapper = styled.div`
  margin-bottom: 6rem;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1380px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: 33.333333%;
  padding-right: 100px;
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1380px) {
    padding-right: 0;
    width: 100%;
    padding-left: 33.333333%;
  }

  @media (max-width: 1024px) {
    padding-left: 0;
  }
`

const Services = () => (
  <Wrapper>
    <Content>
      <Headline>Digital</Headline>
      <Columns>
        <Column>
          <strong>Digitale </strong>
          <Title size="s">Beratung & Projektleitung</Title>
          Die Nutzer stehen immer im Fokus jeder digitalen Umsetzung. Eine
          Begleitung der Prozesse durch Beratung und Erarbeitung der
          Arbeitsprozesse stellen den entscheidenden Baustein für eine
          erfolgreiche Digitalisierung dar. Profitieren Sie von unserer
          langjährigen Projekterfahrung. Wir sind Ihre «Bauherrenvertretung» und
          begleiten Ihr Projekt vom Konzept bis zur Umsetzung. Damit bleiben
          interne Personalressourcen geschont und Sie haben die Unterstützung,
          die Sie brauchen, solange Sie es brauchen.
        </Column>
        <Column>
          <strong>Digitale</strong>
          <Title size="s">Technologie</Title>
          Mit modernen Webtechnologien lassen sich Projekte effizient und
          innovativ realisieren. Individuelle Software mit Webtechnologie
          umgesetzt ist die Lösung, welche genau auf Ihr Unternehmen
          zugeschnitten ist. Es wird Zeit, dem Post-It-Chaos den Kampf
          anzusagen, Abläufe zu beschleunigen und so die Mitarbeiter- und
          Kundenzufriedenheit zu steigern. Kommen Sie gerne mit Ihrem Problem
          auf uns zu. Wir finden eine Lösung.
        </Column>
        <Column>
          <strong>Digitales</strong>
          <Title size="s">Anwendungen</Title>
          Sie haben eine Idee aber finden keine passende Software am Markt, die
          Ihre Bedürfnisse abdeckt? Sprechen Sie mit uns, wir entwickeln
          massgeschneiderte Softwareanwendungen für Sie. Mit unserem Produkt
          ProFlow bieten wir Ihnen eine standardisierte Digitalisierungslösung.
          Mit den passenden Schnittstellen, um den Arbeitsfluss in Ihrem
          Unternehmen perfekt zu organisieren und zu beschleunigen. Machen Sie
          den nächsten Schritt in die Digitalisierung.
        </Column>
      </Columns>
    </Content>
  </Wrapper>
)

export default Services
