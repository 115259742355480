import React from 'react'
import styled from 'styled-components'

import Content from '../Content'
import Title from '../Title'
import Highlight from '../Highlight'
import ArrowRight from '../Icons/ArrowRight'

const Wrapper = styled.div`
  background: #f5f5f5;
  padding: 6rem 0 3rem 0;

  @media (min-width: 1024px) {
    padding: 9rem 0 6rem 0;
  }
`

const Heading = styled.div`
  max-width: 1110px;
  margin-bottom: 48px;
`

const Columns = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Column = styled.div`
  @media (max-width: 1023px) {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding-right: 100px;
  }
`

const Intro = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0 0 0.5em 0;
`

const Text = styled.div`
  font-size: 21px;
`

const Button = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;

  svg {
    margin-left: 8px;
    height: 20px;
    width: auto;
    position: relative;
    top: 1px;
    transition: transform 0.25s ease;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`

const Meta = styled.div`
  max-width: 500px;
`

const Media = styled.div`
  margin-top: 2rem;
`

export const Img = styled.img`
  display: block;
  height: auto;
  width: 100%;
  border-radius: 16px;
  max-width: 100%;
`

export const Rezendo = () => (
  <Wrapper>
    <Content>
      <Heading>
        <Intro>Kundenfeedback</Intro>
        <Title>Mehr Erfolg durch zufriedene Kunden.</Title>
      </Heading>
      <Columns>
        <Column>
          <Text>
            Mit unserer Kundenfeedback-Lösung sammeln Sie wertvolles Feedback.
            Gewinnen Sie klare Einblicke und verbessern Sie damit Ihre Produkte
            und Dienstleistungen. So steigern Sie die Kundenzufriedenheit
            langfristig und sichern sich Ihren Wettbewerbsvorteil.
          </Text>
        </Column>
        <Column>
          <Meta>
            <Text>Verwandeln Sie Ihre Kunden in Promotoren.</Text>
            <br />
            <Button href="https://www.rezendo.com">
              <Highlight>Mehr auf www.rezendo.com</Highlight> <ArrowRight />
            </Button>
          </Meta>
        </Column>
      </Columns>
      <Media>
        <a href="https://www.rezendo.com">
          <Img
            src="/rezendo.jpg"
            alt="Rezendo: Die Kundenfeedback-Lösung für Unternehmen und Organisationen."
          />
        </a>
      </Media>
    </Content>
  </Wrapper>
)
